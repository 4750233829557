import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

const AcceptCookies = () => {
  const [hidden, setHidden] = useState(true);

  const setCookie = (cname: string, cvalue: number, exdays: number) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const getCookie = (cname: string) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const setppCookie = (cname: string, cvalue: number, exdays: number) => {
    setCookie(cname, cvalue, exdays);
    setHidden(true);
  };

  useEffect(() => {
    if (!getCookie("pp")) {
      setHidden(false);
    }
  }, []);

  return (
    <Box hidden={hidden} position="fixed" bottom={0} m={2} zIndex={2000}>
      <Box border="1px solid #dde6ed" bgcolor="#fff" p={2} borderRadius={2}>
        <Typography>
          This website uses cookies to ensure you get the best experience.
          <Typography component={Link} href="/privacy-policy/" target="_blank">
            {" "}
            Learn more
          </Typography>
          <Box component="span" ml={2} display="inline-block">
            <Button
              onClick={() => setppCookie("pp", 1, 365)}
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                borderRadius: "2rem",
                textTransform: "none",
                minWidth: "100px",
                padding: "6px 18px",
                outline: "none",
                "&:focus": {
                  outline: "none",
                },
                "&:hover": {
                  backgroundColor: "#44924C !important",
                  color: "#FFFFFF !important",
                },
              }}
            >
              Got it
            </Button>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default AcceptCookies;
